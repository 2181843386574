import Home from "./pages/Hero";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Contact from "./pages/Contact";
import Services from "./pages/Services";
import Employees from "./pages/Employees";
import Book from "./pages/Book";
// import TagManager from 'react-gtm-module'

// const tagManagerArgs = {
//   gtmId: 'GTM-MWPKZWJ'
// }
// TagManager.initialize(tagManagerArgs)


function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/*" element={<Home />} />
          <Route exact path="/kontakta-oss" element={<Contact />} />
          <Route exact path="/tjanster" element={<Services />} />
          <Route exact path="/personal" element={<Employees />} />
          <Route exact path="/boka" element={<Book />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
