import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "./Button";
import cross_svg from "./../assets/icons/cross-svgrepo-com.svg";
import bars_svg from "./../assets/icons/bars-svgrepo-com.svg";
import logoUrl from "./../assets/images/gigservice-logo.webp";

export default function Navbar() {
  const [click, setClick] = useState(false);

  const handleClick = () => {
    if (click === false) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "overlay";
    }
    setClick(!click);
  };
  const closeMobileMenu = () => {
    setClick(false);
    document.documentElement.style.overflow = "overlay";
  };

  const showButton = () => {
    if (window.innerWidth <= 920 && click === true) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "overlay";
    }
  };
  useEffect(() => {
    showButton();
  });

  window.addEventListener("resize", showButton);

  return (
    <div className="navbar-wrapper">
      <nav className="navbar">
        
      <Link to="/" className="navbar__logo">
    
          <img
            className="navbar__logo__image"
            src={logoUrl}
            alt="Gigservice logo"
          />
    
        </Link>
        
       
        
        <div className="navbar__menu-icon" onClick={handleClick}>
          <img
            width={"35"}
            src={click ? cross_svg : bars_svg}
            className={click ? "fas fa-times" : "fas fa-bars"}
            alt="open and close mobile menu"
          ></img>
        </div>
        <div className="navbar__nav">
          <div
            className={click ? "navbar__nav active" : "navbar__nav inactive"}
          >
            <ul className="navbar__nav__links">
              <li>
                <Link
                  className="navbar__nav__links__link"
                  to="/"
                  onClick={closeMobileMenu}
                >
                  <p
                    className={
                      window.location.pathname === "/"
                        ? "navbar__nav__links__link__border"
                        : ""
                    }
                  >
                    Startsida
                  </p>
                </Link>
              </li>
              <li>
                <Link className="navbar__nav__links__link" to="/kontakta-oss" onClick={closeMobileMenu}>
                  <p
                    className={
                      window.location.pathname === "/kontakta-oss"
                        ? "navbar__nav__links__link__border"
                        : ""
                    }
                  >
                    Kontakta oss
                  </p>
                </Link>
              </li>
              <li>
                <Link className="navbar__nav__links__link" to="/tjanster" onClick={closeMobileMenu}>
                  <p
                    className={
                      window.location.pathname === "/tjanster"
                        ? "navbar__nav__links__link__border"
                        : ""
                    }
                  >
                    Våra tjänster
                  </p>
                </Link>
              </li>
              <li>
                <Link className="navbar__nav__links__link" to="/personal" onClick={closeMobileMenu}>
                  <p
                    className={
                      window.location.pathname === "/personal"
                        ? "navbar__nav__links__link__border"
                        : ""
                    }
                  >
                    För personal
                  </p>
                </Link>
              </li>
            </ul>
            <Button className={"butter"} title={"Boka personal"} url={"boka"} onClick={closeMobileMenu}/>
          </div>
        </div>
      </nav>
    </div>
  );
}
