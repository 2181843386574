import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import React from "react";
import Button from "../components/Button";
import imageUrl from "./../assets/images/hero_lights.webp";
import imageUrl2 from "./../assets/images/lundaspelen-vidvinkel.webp";
import ImageWrapper from "../components/wrappers/ImageWrapper";
import FramedImageWrapper from "../components/wrappers/FramedImageWrapper";
import LogosArea from "../components/LogosArea";

export default function Hero() {
  return (
    <>
      <Navbar />
      <ImageWrapper image={imageUrl} alt={"Hero splash"}>
        <div className="centered-content">
          <div className="scalable-font">
            <div className="width-80-centered">
              <h1 className="scalable-header">Vi bemannar upplevelseindustrin!</h1>
            </div>
            <h2>För små som stora evenemang, vi har er personal!</h2>
          </div>
        </div>
        <div className="horizontal-button-wrapper">
          <Button title={"Boka personal"} url={"boka"} />
          <Button title={"Våra tjänster"} url={"tjanster"} />
        </div>
      </ImageWrapper>
      <FramedImageWrapper image={imageUrl2}  alt={"Lundaspelen vidvinkel"}>
        <div className="centered-content">
          <div className="scalable-font">
            <h2>
              “Allt börjar med en idé och en dröm. En dröm om att skapa nästa
              stora snackis, en festival, konsert eller stor branschmässa?{" "}
              <br />
              <br />
              Oavsett vision kommer personalbehovet vara stort.
              <br />
              <br />
              Med Gigservice som partner garanterar vi att Er
              idé blir till verklighet och att minnen för livet skapas!”
            </h2>
            <h3>— Robin Karlsson, Grundare</h3>
          </div>
        </div>
      </FramedImageWrapper>
      <div className="associates">
        <div className="associates__title">
          <h2>
            <strong>Några av våra nöjda kunder</strong>
          </h2>
        </div>
        <div className="associates__content">
        <LogosArea/>
        </div>
      </div>
      <Footer />
    </>
  );
}
