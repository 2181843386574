import React from "react";

export default function FramedImageWrapper(props) {
  return (
    <div
      className="framed-image-wrapper"
      style={{ backgroundImage: `url(${props.image})` }}
      aria-label={props.alt}
    >
      <div className="framed-image-wrapper__content-wrapper">
        <div className="framed-image-wrapper__content-wrapper__content">
          {props.children}
        </div>
      </div>
    </div>
  );
}
