import React from "react";
import { Link } from "react-router-dom";

export default function SendButton({ title, onClick }) {
  return (
    <li style={{ width: "10em" }}>
      <Link className="button__link" onClick={onClick}>
        <p className="button__link__text">{title}</p>
      </Link>
    </li>
  );
}
