import React from 'react';
import imageUrl1 from "./../assets/images/associate_logos/Allthingslive_arrangör_Loggo.webp";
import imageUrl2 from "./../assets/images/associate_logos/tylo_svart.webp";
import imageUrl3 from "./../assets/images/associate_logos/CT_logo-copy.webp";
import imageUrl4 from "./../assets/images/associate_logos/dustin-logo.webp";
import imageUrl5 from "./../assets/images/associate_logos/fremlab-logo.webp";
import imageUrl6 from "./../assets/images/associate_logos/logotyp_hbgarena_black_sidebyside.webp";
import imageUrl7 from "./../assets/images/associate_logos/pealstreet.webp";
import imageUrl8 from "./../assets/images/associate_logos/scouterna-logo.webp";



const logos = [
  { id: 1, src: imageUrl1, alt: 'Allthingslive-logo',style: 'normal' },
  { id: 2, src: imageUrl2, alt: 'tylö_sound-logo',style: 'normal' },
  { id: 3, src: imageUrl3, alt: 'CT-logo',style: 'normal', size: { width: '350px' }  },
  { id: 4, src: imageUrl4, alt: 'Dustin-logo',style: 'normal', size: { width: '300px' } },
  { id: 5, src: imageUrl5, alt: 'Fremlab-logo',style: 'normal', size: { width: '300px' }  },
  { id: 6, src: imageUrl6, alt: 'helsingborg_arena-logo',style: 'blackAndWhite', size: { width: '300px' } },
  { id: 7, src: imageUrl7, alt: 'pealstreet-logo',style: 'inverted', size: { width: '200px' }, padding: '150px' },
  { id: 8, src: imageUrl8, alt: 'Scounterna-logo',style: 'normal' } ,
  
//styles possible: 
// blackAndWhite
// inverted
// normal
];

const LogosArea = () => {
  return (
    <div className="logos-area">
      {logos.map(logo => (
         <div key={logo.id} className={`logo-container ${logo.style}`}  style={{
          paddingLeft: logo.padding, 
          paddingRight: logo.padding, 
          maxWidth: logo.size?.width || '100%',
          maxHeight: logo.size?.height || '100%'
        }}>
          <img src={logo.src} alt={logo.alt} style={{
              maxWidth: logo.size?.width || '100%', 
              maxHeight: logo.size?.height || '100%' 
            }}/>
        </div>
      ))}
    </div>
  );
};

export default LogosArea;