import React from "react";
import Navbar from "../components/Navbar";
import MainContent from "../components/wrappers/MainContent";
import Footer from "../components/Footer";
import ImageWrapper from "../components/wrappers/ImageWrapper";
import Button from "../components/Button";
import imageUrl from "./../assets/images/Background_splash.webp";

export default function Services() {
  return (
    <>
      <Navbar />
      <MainContent>
        <ImageWrapper image={imageUrl}>
          <div className="centered-content">
            <h2>
              <b>Vi erbjuder</b>
            </h2>
          </div>
          <div className="grid-1-1-1">
            <div className="grid-1-1-1__left">
              <div className="centered-content">
                <h2>Evenemangsvärdar & Projektledare</h2>
                <p>
                  Våra evenemangsvärdar är de som oftast möter era gäster vid
                  entrén. De skannar biljetter, hjälper till i garderoben, visar
                  vägen på evenemanget och är där vid en oväntad situation som
                  eventuell utrymmning för att hjälpa era gäster.
                </p>
                <br />
                <p>
                  Projektledarena har mångårig erfarenhet av evenemang och axlar
                  gärna ansvar från idé till verklighet eller kliver in som
                  koordinatorer på evenemangsdagen för att allt löpa smärtfritt
                  för er och era besökare.
                </p>
              </div>
            </div>
            <div className="grid-1-1-1__middle">
              <div className="centered-content">
                <h2>Restaurangpersonal</h2>
                <p>
                  Är det dags för årets julbord eller har ni fått en extra stor
                  bokning och behöver extra personal? Vi har bartenders,
                  hovmästare, kockar, runners och servitörer i flertalet olika
                  erfarenhetsnivåer för att hitta en prisnivå som passar er.
                </p>
              </div>
            </div>
            <div className="grid-1-1-1__right">
              <div className="centered-content">
                <h2>Stagehands & Tekniker</h2>
                <p>
                  Oavsett om ni behöver hjälp under en festival som varar flera
                  dagar eller enbart under ett par timmar vid en extra hektisk
                  load-in har vi personal med lång erfarenhet av
                  scenproduktioner.
                </p>
                <br />
                <p>Vid behov kan vi även erbjuda ljud & ljustekniker.</p>
              </div>
            </div>
          </div>
          <div className="button-wrapper">
            <Button title={"Boka personal"} url={"boka"}></Button>
          </div>
        </ImageWrapper>
      </MainContent>
      <Footer />
    </>
  );
}
