import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {

  return (
    <div className="footer">
      <div className="footer__content">
        <div className="footer__content__left">
          <div className="footer__content__left__header">
            <h4>
              <strong>Våra sidor</strong>
            </h4>
          </div>
          <div className="footer__content__left__body">
            <div className="footer__content__left__body__links">
              <Link className="footer__content__left__body__links__link" to="/" onClick={() => {window.scrollTo(0, 0)}}>
                <p className="footer__content__left__body__links__link__text">
                  Startsida
                </p>
              </Link>

              <br />
              <Link className="footer__content__left__body__links__link" to="/kontakta-oss" onClick={() => {window.scrollTo(0, 0)}}>
                <p className="footer__content__left__body__links__link__text">
                Kontakta Oss
                </p>
              </Link>
            
              <br />
              <Link className="footer__content__left__body__links__link" to="/tjanster" onClick={() => {window.scrollTo(0, 0)}}>
                <p className="footer__content__left__body__links__link__text">
                Våra Tjänster
                </p>
              </Link>
             
              <br />
              <Link className="footer__content__left__body__links__link" to="/boka" onClick={() => {window.scrollTo(0, 0)}}>
                <p className="footer__content__left__body__links__link__text">
                Boka Personal
                </p>
              </Link>
             
            </div>
          </div>
        </div>
        <div className="footer__content__right">
          <div className="footer__content__right__header">
            <h2>
              <strong>Gigservice</strong>
              <p><i>
              (Gigservice drivs sedan 1 oktober 2023 <br/> i ny regi av <a href="https://www.acesevent.com">ACES Eventbemanning AB</a>)           
                </i></p>
            </h2>
            
          </div>
          <div className="footer__content__right__body">
            <p>
              <a href="mailto:info@event-service.nu">info@event-service.nu</a>
              <br />
              070 - 30 02 521 (Delas med Gigtec){" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
