import React, { useRef, useState } from "react";
import axios from "axios";
import Navbar from "../components/Navbar";
import MainContent from "../components/wrappers/MainContent";
import Footer from "../components/Footer";
import ImageWrapper from "../components/wrappers/ImageWrapper";
import imageUrl from "./../assets/images/falsterbo-middag.webp";
import SendButton from "../components/SendButton";
import LoadingRing from "../components/Loading";

export default function Contact() {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const inputFirstName = useRef(null);
  const inputLastName = useRef(null);
  const inputEmail = useRef(null);
  const inputMessage = useRef(null);

  const SendMail = () => {
    setLoading(true);
    axios
      .post(`https://j5jxy1hiag.execute-api.eu-north-1.amazonaws.com/mail`, {
        Form: "Kontakta-oss",
        Förnamn: inputFirstName.current.value,
        Efternamn: inputLastName.current.value,
        Email: inputEmail.current.value,
        Meddelande: inputMessage.current.value,
      })
      .then((res) => {
        setLoading(false);
        try {
          if (res.data.message === "Ditt meddelande har skickats!") {
            setMessage("Ditt meddelande har skickats!");
          }
          if (res.data.message === "Något gick fel, vänligen försök igen.") {
            setMessage("Något gick fel, försök igen.");
          }
          if (res.data.message === "Vänligen fyll i alla fält...") {
            setMessage("Vänligen fyll i alla fält.");
          }
        } catch (error) {
          setMessage("Okänt fel, försök igen.");
        }
      });
  };

  return (
    <>
      <Navbar />
      <MainContent>
        <ImageWrapper image={imageUrl} alt={"Falsterbo middag"}>
          <div className="grid-1-1">
            <div className="grid-1-1__left">
            <h2 style={{ minWidth: '15em' }}>Kontaka oss</h2>

              <p>
                <b>För eventkreatörer/kunder</b>
              </p>
              <p>
                info[@]event-service.nu
                <br />
                070 - 30 02 521
              </p>

            
            </div>
            <div className="grid-1-1__right">
              <div className="name-input">
                <div className="name-input__firstname">
                  <label>Förnamn *</label>

                  <input ref={inputFirstName}></input>
                  <br />
                </div>
                <div className="name-input__lastname">
                  <label>Efternamn *</label>

                  <input ref={inputLastName}></input>
                  <br />
                </div>
              </div>
              <div className="input-w-label">
                <label>Email *</label>
                <br />
                <input ref={inputEmail}></input>
                <br />
              </div>

              <div className="input-w-label">
                <label>Meddelande *</label>
                <br />
                <textarea ref={inputMessage}></textarea>
              </div>
              <div className="button-wrapper">
                {!loading ? (
                  <>
                    {message === "Ditt meddelande har skickats!" ? (
                      <div />
                    ) : (
                      <SendButton
                        title={"Skicka"}
                        onClick={SendMail}
                      ></SendButton>
                    )}
                    <p className="send-message">{message}</p>
                  </>
                ) : (
                  <LoadingRing />
                )}
              </div>
            </div>
          </div>
        </ImageWrapper>
      </MainContent>
      <Footer />
    </>
  );
}
