import React from "react";

export default function ImageWrapper(props) {
  return (
    <div
      className="image-wrapper"
      style={{ backgroundImage: `url(${props.image})` }}
      aria-label={props.alt}
    >
      <div className="image-wrapper__content-wrapper">
        <div className="image-wrapper__content-wrapper__content">
          {props.children}
        </div>
      </div>
    </div>
  );
}
