import React from "react";
import { Link } from "react-router-dom";
function Button({ title, url }) {
  return (
    <li>
      <Link className="button__link" to={"/" + url}>
        <p className="button__link__text">{title}</p>
      </Link>
    </li>
  );
}

export default Button;
